import * as React from 'react';
import {
  ArrayInput,
  Edit,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  required,
  ReferenceInput,
} from 'react-admin';
import DatetimeInput from 'src/components/DatetimeInput';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { FormGlobalError } from 'src/components/FormGlobalError';
import HiddenInput from 'src/components/HiddenInput';
import { PermissionsInput } from './inputs';
import { pricingTypes, sellerIds } from './field';
import { pick } from 'lodash';

const allowedFields = [
  'name',
  'trialFrom',
  'trialUntil',
  'paidFrom',
  'paidUntil',
  'permissionIds',
  'sellerId',
  'pricingType',
  'packs',
  'labsWandhPlans',
  'updatedAtMillis',
];

const OrganizationEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const OrganizationEdit: React.FC = () => {
  return (
    <Edit mutationMode="pessimistic" transform={(data) => pick(data, allowedFields)}>
      <SimpleForm toolbar={<OrganizationEditToolbar />}>
        <TextField source="oid" />

        <FormGlobalError />

        <HiddenInput source="updatedAtMillis" />
        <TextInput source="name" />
        <CustomDateTimeInput source="trialFrom" />
        <CustomDateTimeInput source="trialUntil" />
        <CustomDateTimeInput source="paidFrom" />
        <CustomDateTimeInput source="paidUntil" />
        <PermissionsInput />
        <SelectInput validate={required()} source="sellerId" choices={sellerIds} />
        <SelectInput source="pricingType" choices={pricingTypes} defaultValue={null} />

        <ArrayInput source="packs">
          <SimpleFormIterator disableRemove disableReordering inline>
            <NumberInput source="packId" />
            <CustomDateTimeInput source="validFrom" />
            <CustomDateTimeInput source="validUntil" />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="labsWandhPlans">
          <SimpleFormIterator disableRemove disableReordering inline>
            <ReferenceInput
              source="basePlanId"
              reference="labsWandhPlans"
              validate={required()}
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              source="addOnPlanId"
              reference="labsWandhPlans"
              allowEmpty
              sort={{ field: 'id', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="maxAddOnPlanUsers" />
            <DatetimeInput source="activeFrom" validate={required()} />
            <DatetimeInput source="activeUntil" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
